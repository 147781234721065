import {
  doc,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  onSnapshot,
  setDoc
} from "firebase/firestore"
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react"
import { Link, useOutletContext, useParams } from "react-router-dom"
import { useFirestore, useSigninCheck, useStorage } from "reactfire"
import { FirestoreUserConverter, User } from "../../FirestoreConverters"
import Loading from "../General/Loading"
import LinkButton from "../General/LinkButton"
import { MyAccountProps } from "../Page/MyAccount";

const KYC = () => {
  const {userId} = useParams()
  const firestore = useFirestore()
  const storage = useStorage()
  const {userInfo} = useOutletContext<MyAccountProps>()

  const {status, data: me} = useSigninCheck({requiredClaims: {
    kycManager: true
  }})

  const [user, setUser] = useState<User | false>()
  const [reportUrl, setReportUrl] = useState<string>()
  useEffect(() => {

    const streamUser = (userRef: DocumentReference<User>, snapshotHandler: (snapshot: DocumentSnapshot<User>) => void, errorHandler: (e: FirestoreError) => void ) => {
      return onSnapshot(userRef, snapshotHandler, errorHandler)
    }

    if (userId === undefined) return

    const userRef = doc(firestore, "users", userId)
      .withConverter(FirestoreUserConverter)

    const unsub = streamUser(
      userRef,
      async (snapshot) => {
        const data = snapshot.data()

        if (data === undefined || data.verification === undefined) return setUser(false)

        const reportRef = ref(storage, data.verification.reportUrl)
        const reportUrl = await getDownloadURL(reportRef)
        setUser(data)
        setReportUrl(reportUrl)
      },
      (e) => {
        setUser(false)
        console.log(e)
      })
      return unsub
  }, [firestore, storage, userId])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const approveKYC = async (user: User) => {
    setIsLoading(true)
    try {
      const userRef = doc(firestore, "users", user.id)
        .withConverter(FirestoreUserConverter)
      await setDoc(userRef, {
        IDIsVerified: true,
        verification: {
          actionedBy: userInfo.email
        }
      }, {
        merge: true
      })
      setHasUpdated(true)
      setTimeout(() => {
        setHasUpdated(false)
      }, 10000)
    } catch (e: any) {
      setError("Failed to update KYC")
      setTimeout(() => {
        setError(undefined)
      }, 10000)
      console.log(e)
    }
    setIsLoading(false)
  }

  const rejectKYC = async (user: User) => {
    setIsLoading(true)
    try {
      const userRef = doc(firestore, "users", user.id)
        .withConverter(FirestoreUserConverter)
      await setDoc(userRef, {
        IDIsVerified: false,
        verification: {
          actionedBy: userInfo.email
        }
      }, {
        merge: true
      })
      setHasUpdated(true)
      setTimeout(() => {
        setHasUpdated(false)
      }, 10000)
    } catch (e: any) {
      setError("Failed to update")
      setTimeout(() => {
        setError(undefined)
      }, 10000)
      console.log(e)
    }
    setIsLoading(false)
  }

  if (userId === undefined) return <h1 className="font-bold uppercase text-xl">No User provided</h1>
  if (status === "loading" || user === undefined || reportUrl === undefined) return <Loading />
  if (me.hasRequiredClaims === false) return <h1 className="font-bold uppercase text-xl">Not authorised</h1>
  if (user === false) return <h1 className="font-bold uppercase text-xl">User not found</h1>
  return <div>
    {hasUpdated === true ?
      <p className="bg-green-300 border border-green-500 max-w-md p-4 mx-auto">
        KYC Updated
      </p>
    : null}

    {error !== undefined?
      <p className="bg-red-300 border border-red-500 max-w-md p-4 mx-auto">
        {error}
      </p>
    : null}

    <h1 className="font-bold uppercase text-xl pb-2">KYC Review</h1>

    <p>
      <span className="font-bold">Current Status:</span> {user.IDIsVerified === true ? "Verified" : "Unverified"}
    </p>

    <p className="py-1">
      <Link to={reportUrl} className="text-my-orange underline" target="_blank">
        View KYC Report for {user.firstName} {user.lastName}
      </Link>
    </p>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 max-w-lg mx-auto">
      <LinkButton
        type="button"
        clickHandler={() => approveKYC(user)}
        disabled={isLoading === true || user.IDIsVerified === true}
      >
        Approve
      </LinkButton>
      <LinkButton
        type="button"
        clickHandler={() => rejectKYC(user)}
        disabled={isLoading === true || user.IDIsVerified === false}
        backgroundColour="my-olive"
      >
        Reject
      </LinkButton>
    </div>
  </div>
}

export default KYC