import {
  Outlet,
  useParams
} from "react-router-dom";
import {
  doc,
  getDoc
} from 'firebase/firestore';
import {
  useAuth,
  useFirestore,
  useSigninCheck
} from 'reactfire';

import {
  useEffect,
  useState } from "react";

import NotFound from "../General/NotFound";
import Loading from "../General/Loading";
import { Event/*, User as Organiser*/, FirestoreEventConverter/*, FirestoreUserConverter*/ } from "../../FirestoreConverters";
import { Auth, User, signInAnonymously } from "firebase/auth";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import Hero from "../General/Hero";

export default function GiftToEvent () {

  const [, setDocumentTitle] = useDocumentTitle()

  const firestore = useFirestore()
  const {eventName} = useParams()
  const [eventData, setEventData] = useState<Event | undefined | false>(undefined)
  //const [organiserData, setOrganiserData] = useState<Organiser | undefined | false>(undefined)
  const [user, setUser] = useState<false | User>(false)

  const auth = useAuth()
  const {status: signInStatus, data: signInCheckResult} = useSigninCheck()

  useEffect(() => {
    const loginAnon = async (auth: Auth) => {
      console.log("logging in anonymously")
      const u = await signInAnonymously(auth)
      return u
    }

    if (signInStatus === "loading") return;
    if (signInStatus === 'success' && signInCheckResult.signedIn === false) {
      loginAnon(auth)
    }

    if (signInStatus === 'success' && signInCheckResult.signedIn === true) {
      console.log("user logged in")
      setUser(signInCheckResult.user)
    }
  }, [auth, signInStatus, signInCheckResult])

  /**
   * Get the event information using the URL slug
   **/
  useEffect(() => {
    const getEventData = async (eventId: string | undefined) => {
      try {
        const eventRef = doc(firestore, `events/${eventId}`).withConverter(FirestoreEventConverter)
        const eventDoc = await getDoc(eventRef)
        const eventDocData = eventDoc.data()
        // Not found
        if (eventDocData === undefined) {
          //setEventData(false)
          return false
        }
        //setEventData(eventDocData)
        return eventDocData
      } catch (e) {
        console.log(e)
        // Error, but we'll say not found
        //setEventData(false)
        return false
      }
    }

    // const getOrganiserData = async (organiserId: string) => {
    //   try {
    //     const userRef = doc(firestore, organiserId).withConverter(FirestoreUserConverter)
    //     const userDoc = await getDoc(userRef)
    //     const userDocData = userDoc.data()
    //     // Not found
    //     if (userDocData === undefined) {
    //       return false
    //     }
    //     return userDocData
    //   } catch (e) {
    //     console.log(e)
    //     // Error, but we'll say not found
    //     return false
    //   }
    // }

    if (user !== false && eventName !== undefined) {
      getEventData(eventName).then((eventData) => {
        setEventData(eventData)

        if (eventData !== false) {
          // getOrganiserData(eventData.organiser).then((organiser) => {
          //   setOrganiserData(organiser)
          // })
          setDocumentTitle(`Contribute to ${eventData.name}`)
        }
      })
    }

  }, [user, eventName, setEventData, setDocumentTitle, firestore])

  let content;
  if (eventData === undefined || user === false) {
    content = <Loading />
  } else if (eventData === false) {
    content = <NotFound />
  } else {
    content = <Outlet context={{eventInfo: eventData, userInfo: user}} />
  }

  return (
    <div className="bg-white">
      {eventData !== false && eventData !== undefined ?
        <Hero
          // bg={`bg-hero-${eventData && eventData.isGifting? eventData.type : "confetti"}`}
          title={eventData.name}
          sub={eventData.date ? eventData.date.toLocaleDateString() : undefined}
        />
      : null}

      {eventData && eventData.hasEnded
        && <p className="text-my-grey font-bold pt-4 text-2xl">This occasion has now passed!</p>
      }

      <div className="mx-auto max-w-screen-lg text-my-grey p-4">
        {content}
      </div>
    </div>
  )
}