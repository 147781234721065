import {
  DocumentData,
  DocumentReference,
  Timestamp
} from "firebase/firestore"

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  acceptTC: boolean;
  marketing: boolean;
  createdAt: Date;
  id: string;
  IDIsVerified: boolean;
  verification?: {
    response: any;
    expiry: Timestamp;
    reportUrl: string;
    actionedBy?: string;
  },
  acquiredCustomerId?: string;
}

const FirestoreUserConverter = {
  toFirestore: (data: User) => data,
  fromFirestore: (doc: DocumentData): User => {
    const d = doc.data()
    if (d.IDIsVerified === undefined) {
      d.IDIsVerified = false
    }

    // if (
    //   d.verification &&
    //   d.verification.response &&
    //   d.verification.response.results.length > 0
    // ) {
    //   if (d.verification.response.results[0].totalscore < 8) {
    //     d.IDIsVerified = true
    //   }
    // }
    // d.IDIsVerified = true
    d.id = doc.id
    return d as User
  }
}

export type BankAccount = {
  // accountName: string;
  sortCode: string;
  accountNumber: string;
}

export type AcquiredPayee = {
  nickname: string;
  ending: string;
  payeeId: string;
}

export type BaseEvent = {
  name: string;
  message: string;
  organiser: DocumentReference<DocumentData>;
  type: string;
  date: Date | null; // null == open-ended
  feeIncluded: boolean; // false == contributor pays
  isGifting: boolean; // false == general
  createdAt: Timestamp;
  redeemInProgress?: boolean;
}

export interface Event extends BaseEvent {
  id: string;
  totalAmount: number;
  hasEnded: boolean;
  hasBeenClaimed: boolean;
  authRequest?: PaymentRequest;
  paymentJobData?: PaymentJob | BankAccount;
}

const FirestoreEventConverter = {
  toFirestore: (data: Event) => data,
  fromFirestore: (doc: DocumentData): Event => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const e = doc.data()
    e.id = doc.id

    // Convert the Firestore timestamp to a Date
    if(e.date !== null) {
      e.date = e.date.toDate()
      // Set the time to midnight
      e.date.setHours(0, 0, 0, 0)
    }

    // backfill general or gifting
    if (e.isGifting === null) {
      e.isGifting = true
    }

    // backfill fee control
    if (e.feeIncluded === null || e.feeIncluded === undefined) {
      e.feeIncluded = false
    }

    // backfill totalAmount
    e.totalAmount = e.totalAmount || 0

    e.hasEnded = (e.date !== null && e.date < today) ? true : false
    e.hasBeenClaimed = e.hasBeenClaimed || false
    e.redeemInProgress = e.redeemInProgress || false

    return e
  }
}

type BaseContribution = {
  id?: string;
  feeAmount: number;
  giftAmount: number;
  totalAmount: number;
  giftedAt: Timestamp;
  email?: string;
  message?: string;
  cardholderFName?: string;
  cardholderLName?: string;
  paymentRef?: string;
  paymentReq?: PaymentRequest;
  paymentJob?: PaymentJob;
  status: PaymentStatus;
  uid: string;
  type: ContributionTypes
}

export type GeneralContribution = BaseContribution & {
  type: ContributionTypes.GENERAL;
}

export type GiftContribution = BaseContribution & {
  type: ContributionTypes.GIFT;
}

export enum BillTypes {
  INSTANT = "instant",
  EMAIL   = "email"
}

export type BillContribution = BaseContribution & {
  type: ContributionTypes.BILL;
  billType: BillTypes;
}

export type RedeemContribution = BaseContribution & {
  type: ContributionTypes.REDEEM;
}

export enum ContributionTypes {
  BILL    = "bill",
  GIFT    = "gift",
  REDEEM  = "redeem",
  GENERAL = "general"
}

export type Gift = GiftContribution | GeneralContribution | BillContribution | RedeemContribution;

export enum PaymentStatus {
  PENDING   = "PENDING",
  PAID      = "PAID",
  FAILED    = "FAILED",
  CANCELLED = "CANCELLED",
  VERIFIED  = "VERIFIED",
  DELETED   = "DELETED"
}

export type PaymentRequest = {
  amountToCollect: string;
  type: string;
  currency: string;
  locale: string;
  order: {
    orderNumber: string;
    billingAddress?: {
      firstName: string;
      lastName: string;
      addressLine1?: string;
      zipCode?: string;
      countryIso3166Alpha2?: "GB";
    },
    billingIdentity?: {
      emailAddress: string;
    },
    orderLines?: PaymentJobOrderLines[]
  },
  parameters: {
    // These should only take the form of the URL itself and not include hostname
    returnUrlSuccess: string;
    returnUrlCancelled: string;
    returnUrlFailed: string;
    webhookUrl: PaymentJobWebhookUrls;
  },
  options?: PaymentJobOptions[]
}

export enum PaymentJobWebhookUrls {
  PaymentUpdate = "/cashflows-webhook",
  RedeemUpdate = "/redeem-webhook"
}

export enum PaymentJobOptions {
  GenerateToken = "GenerateToken",
  StoreCustomerInformation = "StoreCustomerInformation"
}

export type PaymentJobOrderLines = {
  description?: string;
  lineNumber?: string;
  type?: string;
  name?: string;
  quantity?: number;
  unitPriceExclVat?: number;
  unitPriceInclVat?: number;
  vatPercentage?: number;
  vatPercentageLabel?: string;
  discountPercentageLabel?: string;
  totalLineAmount?: number;
  url?: string;
}

export type PaymentJob = {
  data: {
    reference: string;
    createDateTimeUtc: string;
    type: string;
    traceReference: string;
    configurationId: string;
    domain: string;
    locale: string;
    timeZone: string;
    order: {
      orderNumber: string;
      createDateTimeUtc: string;
      billingAddress: {
        firstName: string;
        lastName: string;
      },
      billingIdentity: {
        emailAddress: string;
      },
      orderLines: []
    },
    orderHistory: [],
    paymentMethodsToUse: [
      "Card"
    ],
    currency: string;
    amountToCollect: string;
    expirationDateTimeUtc: string;
    lastUpdateTimeUtc: string;
    lastProcessedTimeUtc: string;
    flags: {},
    attributes: {},
    paymentStatus: string;
    payments: [
      {
        reference: string;
        createDateTimeUtc: string;
        paymentMethods: [
          "Card"
        ],
        status: string;
        amountToCollect: string;
        steps: [
          {
            reference: string;
            createDateTimeUtc: string;
            action: string;
            paymentMethods: [
              "Card"
            ],
            status: string;
            amountToCollect: string;
          }
        ],
        flags: {},
        attributes: {
          returnUrlSuccess: string;
          returnUrlFailed: string;
          returnUrlCancelled: string;
        },
        refunds: [],
        captures: []
      }
    ]
  },
  links: {
    data: {
      url: string;
      type: string;
    },
    action: {
      url: string;
      type: string;
    },
    documentation: {
      url: string;
      type: string;
    }
  }
}

export type PaymentResult = {
  result: string;
  transactionId: string;
}

const FirestoreGiftConverter = {
  toFirestore: (data: Gift) => {
    return data
  },
  fromFirestore: (doc: DocumentData): Gift => {
    const g = doc.data()
    g.id = doc.id

    if (g.status === undefined) {
      g.status = PaymentStatus.PENDING
    }

    switch (g.type) {
      case ContributionTypes.BILL:
        g.billType = g.billType === undefined ? BillTypes.EMAIL : g.billType;
        return g as BillContribution
      case ContributionTypes.GIFT:
        return g as GiftContribution
      case ContributionTypes.REDEEM:
        return g as RedeemContribution
      case ContributionTypes.GENERAL:
      default:
        return g as GeneralContribution
    }
  }
}

export {
  FirestoreUserConverter,
  FirestoreEventConverter,
  FirestoreGiftConverter
}