import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { Event, FirestoreGiftConverter, Gift, PaymentStatus } from "../../FirestoreConverters"
import LinkButton from "../General/LinkButton"
import QRCode from "./QRCode"
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import { MyAccountProps } from "../Page/MyAccount"
import GiftList from "./GiftList"
import PendingPaymentList from "./PendingPaymentList"
import CreateBillModal from "./CreateBillModal"
import Modal from "../General/Modal"
import { doc, DocumentReference, DocumentSnapshot, FirestoreError, onSnapshot } from "firebase/firestore"
import { useFirestore } from "reactfire"

const EventAdminInfo = () => {
  const {event: eventData, userInfo, setDocumentTitle, setSubTitle} = useOutletContext<MyAccountProps & {event: Event}>()

  setDocumentTitle(eventData ? eventData.name : "")
  setSubTitle(eventData && eventData.date !== null ? eventData.date.toLocaleDateString() : "Always Open")

  const [displayAmounts, setDisplayAmounts] = useState<boolean>(false)
  const [openMessage, setOpenMessage] = useState<boolean>(false)

  const [eventUrl, setEventUrl] = useState<string | false>(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false)
  const copyLink = async () => {
    if (!eventUrl) return
    await navigator.clipboard.writeText(`${eventUrl}?utm_source=jaradm&utm_medium=link`)
    setCopiedToClipboard(true)
    setTimeout(() => {
      setCopiedToClipboard(false)
    }, 5000)
  }

  useEffect(() => {
    setEventUrl(`http://${window.location.host}/contribute-to/${eventData.id}`)
  }, [eventData])

  const [showCreateTxnForm, setShowCreateTxnForm] = useState(false)
  const [billToRepeat, setBillToRepeat] = useState<Gift>()
  const [qrToShow, setQrToShow] = useState<Gift>()
  const [qrOnShow, setQrOnShow] = useState<Gift>()

  const firestore = useFirestore()
  useEffect(() => {
    if (qrToShow === undefined) return

    const streamGift = (
      giftRef: DocumentReference<Gift>,
      snapshotHandler: (snapshot: DocumentSnapshot<Gift>) => void,
      errorHandler: (e: FirestoreError) => void
    ) => {
      console.log("Streaming gift...")
      return onSnapshot(giftRef, snapshotHandler, errorHandler)
    }

    const giftRef = doc(firestore, `events/${eventData.id}/gifts/${qrToShow.id}`)
      .withConverter(FirestoreGiftConverter)

    const unsub = streamGift(
      giftRef,
      (snapshot) => {
        console.log("got new snapshot", {snapshot})
        setQrOnShow(snapshot.data())
      },
      (e) => {
        setQrOnShow(undefined)
        console.log(e)
      })
    return unsub
  }, [firestore, eventData, qrToShow])

  return (<div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 text-left">
    {showCreateTxnForm === true ?
      <CreateBillModal event={eventData} userInfo={userInfo} closeFunction={(show: boolean) => {
        setBillToRepeat(undefined)
        setShowCreateTxnForm(show)
      }} setQrToShow={setQrToShow} billToRepeat={billToRepeat} />
    : null}
    {
      qrOnShow !== undefined
      && qrOnShow.id !== undefined
    ?
      <Modal
        closeFunction={(x) => {
          setQrToShow(undefined)
          setQrOnShow(undefined)
        }}
        title={`Bill for £${qrOnShow.totalAmount.toFixed(2)}`}
      >
        {qrOnShow.status === PaymentStatus.PENDING || qrOnShow.status === PaymentStatus.FAILED ?
          <>
            <QRCode
              eventUrl={`http://${window.location.host}/contribute-to/${eventData.id}/bill/${qrOnShow.id}`}
              eventName={eventData.name}
              showDownloadButton={false}
            />

            <p className="text-center py-1">Scan the QR Code to make payment</p>
          </>
        : null}

        {qrOnShow.status === PaymentStatus.PAID ?
          <>
            <div className="mx-20">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="inline align-bottom text-my-olive">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
              </svg>
            </div>
            <p className="text-center">Bill has been paid</p>
          </>
        : null}

        {qrOnShow.status === PaymentStatus.CANCELLED ?
          <div className="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-72 inline align-bottom text-red-600">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            <p className="py-1">
              The bill payer has cancelled the bill. If you would like to recreate this bill, please do so in the 'unpaid bills' section
            </p>
          </div>
        : null}

        <div className="py-2 md:py-5 border-t border-gray-200 rounded-b text-center">
          <ul>
            {eventData.feeIncluded === false ?
              <>
                <li>Transaction Fee: &pound;{qrOnShow.feeAmount.toFixed(2)}</li>
                <li>Contribution Amount: &pound;{qrOnShow.giftAmount.toFixed(2)}</li>
              </>
            : null }
            <li className="font-bold">Bill Total: &pound;{qrOnShow.totalAmount.toFixed(2)}</li>
          </ul>
        </div>
      </Modal>
    : null}

    {eventData.hasEnded ?
      <p className="text-my-grey text-lg p-4 bg-green-200 text-center border-2 md:col-span-3 m-2 md:m-0 mb-0">
        {eventData.totalAmount > 0 && eventData.hasBeenClaimed ?
          <>Your jar is now closed and has been redeemed</>
        :
          <>Your jar is now closed {eventData.totalAmount > 0 && eventData.redeemInProgress === false ? "and can be redeemed" : null}</>
        }
      </p>
    : null}

    <div className="order-1 md:order-2">
      <h3 className="uppercase text-lg p-4 text-white bg-my-olive font-bold">Manage</h3>
      <div className="text-center p-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-2">
        {eventData.isGifting ? <>
          <LinkButton type="button" clickHandler={() => setOpenMessage(!openMessage)}>{!openMessage ? "View Messages" : "Hide Messages"}</LinkButton>
          <LinkButton type="button" clickHandler={() => setDisplayAmounts(!displayAmounts)}>{!displayAmounts ? "View Contributions" : "Hide Contributions"}</LinkButton>
        </>
        : null}

        {eventData.isGifting === false && eventData.hasEnded === false ?
          <LinkButton type="button" clickHandler={() => setShowCreateTxnForm(true)}>Create Bill</LinkButton>
        : null}

        {!eventData.hasEnded ? <LinkButton type="link" url="edit">Edit Jar</LinkButton>
        : null}

        {(
          // Hasn't already been claimed
          eventData.hasBeenClaimed === false
          // Has some contributions
          && eventData.totalAmount > 0
          // Isn't currently being redeemed
          && eventData.redeemInProgress === false
          && (
            // Gifting & has ended
            (eventData.isGifting === true && eventData.hasEnded === true)
            ||
            // Open ended
            (eventData.isGifting === false && eventData.date === null)
          )
        ) ?
          <LinkButton type="link" url="redeem">Redeem Contributions</LinkButton> : null
        }
      </div>
    </div>

    <div className="md:col-span-2 md:row-span-4 order-2 md:order-1">
      {eventData.isGifting === false ? <>
        <PendingPaymentList
          event={eventData}
          setQrToShow={setQrToShow}
          userInfo={userInfo}
        />
      </>
      : null}

      <h3 className="uppercase text-xl p-4 text-white bg-my-olive font-semibold grid grid-cols-2 gap-1"><span>Contributions</span><span className="text-right">Total: &pound;{displayAmounts === true || eventData.isGifting === false ? eventData.totalAmount.toFixed(2) : "??.??"}</span></h3>
      <GiftList
        event={eventData}
        showAmounts={eventData.isGifting ? displayAmounts : true}
        showMessages={eventData.isGifting ? openMessage : true}
        setBillToRepeat={(bill: Gift) => {
          setBillToRepeat(bill)
          setShowCreateTxnForm(true)
        }}
      />
    </div>

    <div className="order-3">
      {!eventData.hasEnded && <>
        <h3 className="uppercase text-lg p-4 text-white bg-my-olive font-bold">Share</h3>
        <div className="p-2 text-center">
          <LinkButton
            type="button"
            clickHandler={copyLink}
          >
            {!copiedToClipboard ? "Copy jar link" : "Link copied!"}
          </LinkButton>

          <div className="mt-2">
            <QRCode
              eventUrl={`${eventUrl}?utm_source=jaradm&utm_medium=qrcode`}
              eventName={eventData.name}
              showDownloadButton={true}
            />
          </div>
          {eventUrl !== false ?
          <div>
            <FacebookShareButton
              url={`${eventUrl}?utm_source=jaradm&utm_medium=facebook`}
            >
              <img
                src="/img/socials/fb-olive.png"
                alt={`Share ${eventData.name} on Facebook`}
                className="w-10 mx-1"
              />
            </FacebookShareButton>
            <WhatsappShareButton
              url={`${eventUrl}?utm_source=jaradm&utm_medium=whatsapp`}
              title={`Contribute to ${eventData.name}`}
            >
              <img
                src="/img/socials/wa-olive.png"
                alt={`Share ${eventData.name} to WhatsApp`}
                className="w-10 mx-1"
              />
            </WhatsappShareButton>
            <TwitterShareButton
              url={`${eventUrl}?utm_source=jaradm&utm_medium=x`}
            >
              <img
                src="/img/socials/x-olive.png"
                alt={`Share ${eventData.name} to X/Twitter`}
                className="w-10 mx-1"
              />
            </TwitterShareButton>
            <EmailShareButton
              url={`${eventUrl}?utm_source=jaradm&utm_medium=email`}
            >
              <img
                src="/img/email-white-icon.png"
                alt={`Share ${eventData.name} via Email`}
                className="w-10 mx-1 bg-my-olive bg-clip rounded-xl"
              />
            </EmailShareButton>
          </div>
          : null}
        </div>
      </>}
    </div>
  </div>)
}

export default EventAdminInfo