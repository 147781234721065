import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import Hero from "../General/Hero"

const Privacy = () => {
  const pageTitle = "Privacy Policy"
  useDocumentTitle(pageTitle)
  return (
    <div className="bg-white">
      <Hero
        title={pageTitle}
      />
      <div className="mx-auto max-w-screen-md text-left text-my-grey p-4 text-sm">
        <iframe
          className="w-full min-h-screen"
          src="https://onedrive.live.com/embed?resid=EDA312403D99D75B%21825&amp;authkey=!ANvy-qk8b0UP37E&amp;em=2&amp;wdPrint=0&amp;wdEmbedCode=0"
          title="Privacy Policy"
        >
        </iframe>
      </div>
    </div>
  )
}

export default Privacy