import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import Hero from "../General/Hero"

const Terms = () => {
  const pageTitle = "Terms & Conditions"
  useDocumentTitle(pageTitle)
  return (
    <div className="bg-white">
      <Hero
        title={pageTitle}
      />
      <div className="mx-auto max-w-screen-md text-left text-my-grey p-4 text-sm">
        <iframe
          className="w-full min-h-screen"
          src="https://onedrive.live.com/embed?resid=EDA312403D99D75B%21705&amp;authkey=!AF37VN6h5RmS9Fg&amp;em=2&amp;wdPrint=0&amp;wdEmbedCode=0"
          title="Terms & Conditions"
        ></iframe>
      </div>
    </div>
  )
  }

export default Terms